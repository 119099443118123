.btn-row a.button-main, .btn-row .button-main, .btn-row .main-button, a.button-secondary, .button-secondary, a.button-main, .button-main, .main-button {
  text-align: center;
  border: 0;
  width: 200px;
  margin-top: 10px;
  padding: 20px 0;
  font-family: Source Sans Pro, arial, sans-serif;
  font-size: 13px;
  font-weight: bolder;
  line-height: 15px;
  text-decoration: none;
  transition: background-color .5s;
  display: block;
  position: relative;
}

.btn-row .button-main:disabled, .btn-row .main-button:disabled, .button-secondary:disabled, .button-main:disabled, .main-button:disabled {
  color: #707b87;
  background: #b2d0dd;
}

.btn-row .button-main:focus, .btn-row .main-button:focus, .button-secondary:focus, .button-main:focus, .main-button:focus {
  outline: 0;
}

.btn-row .button-main:active, .btn-row .main-button:active, .button-secondary:active, .button-main:active, .main-button:active {
  background: #02b3bb;
}

.btn-row .button-main > .icon, .btn-row .main-button > .icon, .button-secondary > .icon, .button-main > .icon, .main-button > .icon {
  background-position: center;
  width: 100%;
  height: 15px;
  display: inline-block;
}

.btn-row .icon.button-main, .btn-row .icon.main-button, .icon.button-secondary, .icon.button-main, .icon.main-button {
  width: 40px;
}

a.button-main, .button-main, .main-button {
  color: #fff;
  background: #2dccd3;
}

a.button-main:hover:enabled, .button-main:hover:enabled, .main-button:hover:enabled {
  color: #fff;
  background: #2dccd3;
  text-decoration: none;
}

a.button-main.clicked:hover:enabled, .button-main.clicked:hover:enabled, .main-button.clicked:hover:enabled {
  background: #02b3bb;
}

a.button-secondary, .button-secondary {
  color: #fff;
  background: #707b87;
}

a.button-secondary:hover:enabled, .button-secondary:hover:enabled {
  color: #fff;
  background: #1d223c;
  text-decoration: none;
}

.button-social {
  width: 78px;
  height: 26px;
  display: inline-block;
  position: relative;
}

.button-social:hover span {
  text-decoration: underline;
}

.button-social .button-social-icon {
  width: 20px;
  height: 15px;
  position: absolute;
  top: 0;
}

.button-social .button-social-icon div {
  height: 15px;
}

.button-social span {
  color: #fff;
  vertical-align: middle;
  padding-left: 33px;
  font-size: 12px;
  font-weight: bold;
  line-height: 26px;
  display: inline-block;
}

@media (max-width: 800px) {
  .button-social span {
    text-align: center;
    width: 100%;
    padding: 0 0 0 10px;
  }
}

.button-facebook {
  background-color: #4868ba;
}

.button-facebook .button-social-icon {
  top: 5px;
  left: 12px;
}

.button-facebook .button-social-icon svg {
  height: 15px;
}

.button-twitter {
  background-color: #5db3e6;
}

.button-twitter .button-social-icon {
  top: 5px;
  left: 7px;
}

.button-twitter .button-social-icon svg {
  height: 15px;
}

@font-face {
  font-family: sansregular;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot?v=9");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff?v=9") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.ttf?v=9") format("truetype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.svg#open_sansregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: open_sanslight;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-light-webfont.eot?v=9");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-light-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-light-webfont.woff?v=9") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-light-webfont.ttf?v=9") format("truetype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-light-webfont.svg#open_sanslight") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: sanssemibold;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-semibold-webfont.eot?v=9");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-semibold-webfont.woff?v=9") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-semibold-webfont.ttf?v=9") format("truetype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-semibold-webfont.svg#open_sanssemibold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: sansregular;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot?v=9");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff?v=9") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.ttf?v=9") format("truetype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.svg#open_sansregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: open_sanslight;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-light-webfont.eot?v=9");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-light-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-light-webfont.woff?v=9") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-light-webfont.ttf?v=9") format("truetype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-light-webfont.svg#open_sanslight") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: sanssemibold;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-semibold-webfont.eot?v=9");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-semibold-webfont.woff?v=9") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-semibold-webfont.ttf?v=9") format("truetype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-semibold-webfont.svg#open_sanssemibold") format("svg");
  font-weight: normal;
  font-style: normal;
}

.circle {
  background: #b6c1cc;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  display: inline-block;
}

.circle-active {
  background: #ec86d0;
}

.circle-separator {
  background: #707b87;
  width: 5px;
  height: 5px;
  margin: 0 0 3px 5px;
  display: inline-block;
}

.button {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  background: #2dccd3;
  width: 160px;
  margin: 0 auto;
  padding: 8px 15px;
  font-family: sanssemibold, arial;
  font-size: 11px;
}

.button:active {
  outline: 1px solid #2a5;
}

.circle-play {
  cursor: pointer;
  background: #2dccd3;
  border: none;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  transition: all .3s;
  position: relative;
}

.arrow-play {
  border-top: 8px solid rgba(0, 0, 0, 0);
  border-bottom: 8px solid rgba(0, 0, 0, 0);
  border-left: 10px solid #fff;
  font-size: 0;
  position: absolute;
  top: 12px;
  left: 17px;
}

.arrow-link {
  border-top: 5px solid rgba(0, 0, 0, 0);
  border-bottom: 5px solid rgba(0, 0, 0, 0);
  border-left: 6px solid #2dccd3;
  width: 1px;
  margin-right: 15px;
  font-size: 0;
  display: inline-block;
}

.arrow-link-left {
  border-left: 0;
  border-right: 6px solid #2dccd3;
}

.arrow-down {
  border-top: 5px solid #cad5df;
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0);
  display: inline-block;
  position: absolute;
  top: 0;
  left: 37%;
}

.arrow-link2 {
  vertical-align: middle;
  border-top: 3px solid rgba(0, 0, 0, 0);
  border-bottom: 3px solid rgba(0, 0, 0, 0);
  border-left: 3px solid red;
  margin-left: 15px;
  font-size: 0;
  display: inline-block;
}

.whiteArrow {
  border-top: 4px solid rgba(0, 0, 0, 0);
  border-bottom: 4px solid rgba(0, 0, 0, 0);
  border-left: 6px solid #fff;
  margin-bottom: 1px;
  margin-left: 5px;
  font-size: 0;
  display: inline-block;
}

.timeline {
  text-transform: uppercase;
  min-height: 100px;
  padding-top: 0;
  position: relative;
}

.timeline .time-point {
  box-sizing: border-box;
  vertical-align: middle;
  background: none;
  border: 2px solid #ec86d0;
  border-radius: 20px;
  width: 10px;
  height: 10px;
  margin-left: 0;
  display: inline-block;
  position: relative;
}

.timeline .time-point .actual-week {
  display: none;
}

.timeline .time-point:first-of-type {
  margin-left: 0 !important;
}

.timeline .time-point.time-point-outside {
  width: 12px;
  height: 12px;
  margin-left: 5px;
}

.timeline .time-point:hover, .timeline .time-point.active {
  background: #2dccd3;
  border-color: #2dccd3;
  position: relative;
}

.timeline .time-point:hover .actual-week, .timeline .time-point.active .actual-week {
  box-sizing: border-box;
  color: #fff;
  text-align: center;
  background: #2dccd3;
  border-radius: 18px;
  width: 18px;
  height: 18px;
  padding: 1px 1px 0;
  font-size: 11px;
  display: block;
  position: absolute;
  top: -6px;
  left: -6px;
}

.timeline .time-point:hover .title, .timeline .time-point.active .title {
  display: inline-block;
}

.timeline .time-point:nth-child(17), .timeline .time-point:nth-child(31) {
  margin-right: 32px;
}

.timeline .time-point .title {
  text-transform: none;
  color: #111;
  background-color: #fff;
  border: 1px solid #cad5df;
  width: 129px;
  padding: 2px 5px;
  font-family: sanssemibold, arial;
  font-size: 12px;
  display: none;
  position: absolute;
  top: -38px;
  left: -65px;
}

.timeline .time-point-active {
  opacity: 1;
  filter: alpha(opacity= 100);
  margin-left: 9px;
}

.timeline .time-point-trimestr {
  background: #ec86d0;
  width: 20px;
  height: 20px;
  margin: 0 18px 0 32px;
}

.timeline .sup-info {
  text-align: center;
  margin-bottom: 5px;
  font-size: 10px;
}

.timeline .sup-info span {
  margin-left: 64px;
  margin-right: 102px;
}

.timeline .sub-info {
  color: #2dccd3;
  height: 30px;
  margin-top: 10px;
}

.timeline .sub-info div {
  float: left;
  text-align: center;
  border-top: 1px solid #cad5df;
  margin-left: 20px;
  padding-top: 15px;
  font-family: sanssemibold, arial;
  font-size: 10px;
  position: relative;
}

.timeline .sub-info div.before {
  width: 53px;
}

.timeline .sub-info div.start, .timeline .sub-info div.finish {
  width: 36px;
  margin-left: 27px;
}

.timeline .sub-info div.during {
  width: 625px;
}

.timeline .sub-info div.during .arrow-down {
  left: 49%;
}

.timeline .sub-info div.after {
  width: 95px;
}

.timeline .sub-info div.after .arrow-down {
  left: 46%;
}

.timeline .sub-info div:first-of-type {
  margin-left: 0;
}

.timeline.article-timeline {
  text-align: center;
}

.timeline.article-timeline .week-paging {
  float: left;
  width: 467px;
}

.timeline.article-timeline .dots {
  color: #ec86d0;
  margin-left: 10px;
  font-size: 30px;
}

.timeline.article-timeline .next-art, .timeline.article-timeline .prev-art {
  width: 10px !important;
  margin: 8px 0 0 2px !important;
}

.timeline.article-timeline .time-point {
  display: none;
}

.timeline.article-timeline .time-point:nth-child(17), .timeline.article-timeline .time-point:nth-child(31) {
  margin-right: 0 !important;
}

.timeline.article-timeline .time-point.visible {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  font-family: sanssemibold, arial;
  display: inline-block;
}

.timeline.article-timeline .time-point.visible .actual-week {
  width: 25px;
  height: 25px;
  padding-top: 3px;
  font-size: 14px;
  display: block;
  position: absolute;
  top: 21px;
  left: -4px;
}

.timeline.article-timeline .time-point.active {
  width: 30px !important;
  height: 30px !important;
}

.timeline.article-timeline .time-point.active .actual-week {
  width: 25px;
  height: 25px;
  padding-top: 0;
  font-size: 18px;
  top: 0;
  left: 1px;
}

.sort-choice {
  margin-bottom: 10px;
  font-size: 13px;
}

.sort-choice span {
  margin-right: 40px;
}

.sort-choice span:hover {
  cursor: pointer;
}

.line-choice {
  border-bottom: 1px solid #cad5df;
  position: relative;
}

.line-choice .arrow-down {
  border-top: 10px solid #cad5df;
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
  top: 0;
  left: 23px;
}

.line-choice .arrow-down .inner {
  border-top: 9px solid #fff;
  border-left: 9px solid rgba(0, 0, 0, 0);
  border-right: 9px solid rgba(0, 0, 0, 0);
  top: -10px !important;
  left: -9px !important;
}

.checker {
  z-index: 5;
  position: absolute;
  top: -9px;
  left: 37px;
}

.not-active {
  display: none !important;
}

.ml10px {
  margin-left: 10px;
}

.ml20px {
  margin-left: 20px;
}

.mr10px {
  margin-right: 10px;
}

.mr20px {
  margin-right: 20px;
}

.mt10px {
  margin-top: 10px;
}

.mt20px {
  margin-top: 20px;
}

.mb10px {
  margin-bottom: 10px;
}

.mb20px {
  margin-bottom: 20px;
}

.mb50px {
  padding-bottom: 50px;
}

.mt50px {
  margin-top: 50px;
}

.mr50px {
  margin-right: 50px;
}

.ml50px {
  margin-left: 50px;
}

.mb40px {
  padding-bottom: 40px;
}

.mt40px {
  margin-top: 40px;
}

.mr40px {
  margin-right: 40px;
}

.ml40px {
  margin-left: 40px;
}

.pl10px {
  padding-left: 10px;
}

.pl20px {
  padding-left: 20px;
}

.pr10px {
  padding-right: 10px;
}

.pr20px {
  padding-right: 20px;
}

.pt10px {
  padding-top: 10px;
}

.pt20px {
  padding-top: 20px;
}

.pb10px {
  padding-bottom: 10px;
}

.pb20px {
  padding-bottom: 20px;
}

.mimibazar-block.customizable {
  border-top: 0;
  margin: 0 0 20px;
}

.mimibazar-block.customizable h2 {
  color: #ec86d0;
  text-transform: none;
  margin: 20px 0 10px;
  font-family: sanssemibold, arial;
  font-size: 20px;
}

.mimibazar-block.customizable .item .image {
  background: #eee;
}

.mimibazar-block.customizable .item .title {
  color: #16212d;
}

.mimibazar-block.customizable .item .price {
  color: #ec86d0;
}

.mimibazar-block.customizable .item .more {
  color: #2dccd3;
  border-bottom-color: #2dccd3;
}

.mimibazar-block.customizable .item .more:after, .mimibazar-block.customizable .item .more:before {
  background-color: #2dccd3;
}

.mimibazar-block.customizable .item .more:hover {
  color: #98a3af;
}

.mimibazar-block.customizable .item .more:hover:after, .mimibazar-block.customizable .item .more:hover:before {
  background-color: #98a3af;
}

body {
  color: #48535f;
  overflow-anchor: none;
  background: #eee;
  margin: 0;
  font-family: open_sanslight, arial;
}

a {
  color: #ec86d0;
  text-decoration: none;
  transition: color .2s;
  display: inline-block;
}

a:hover {
  color: #2dccd3;
}

h1 {
  color: #ec86d0;
  margin-top: 10px;
  font-family: sanssemibold, arial;
  font-size: 31px;
}

h2, h3 {
  font-family: sanssemibold, arial;
}

a[href], label[for], select, button, .pointer {
  cursor: pointer;
}

input[type="submit"] {
  cursor: pointer;
}

input[type="image"] {
  cursor: pointer;
}

img {
  border: none;
}

.clear {
  clear: both;
}

#breadcrumbs {
  box-sizing: border-box;
  background-color: #fff;
  width: 940px;
  margin: 0 auto;
  padding: 7px 320px 7px 0;
  font-size: 12px;
}

#breadcrumbs a {
  color: #aaa;
  display: inline;
}

#breadcrumbs a:hover {
  color: #ec86d0;
}

#breadcrumbs .arrow {
  border-top: 4px solid rgba(0, 0, 0, 0);
  border-bottom: 4px solid rgba(0, 0, 0, 0);
  border-left: 6px solid #cad5df;
  margin: 0 5px;
  font-size: 0;
  display: inline-block;
}

.widesqueare-promo {
  clear: both;
  margin-top: 10px;
  margin-bottom: 10px;
}

#site-materstvi {
  text-align: left;
  background-color: #fff;
  width: 940px;
  min-height: 900px;
  margin: 0 auto;
  padding: 5px 70px 70px;
  position: relative;
}

#site-materstvi .wide div, #site-materstvi .wide div img {
  max-width: 940px !important;
}

.footer {
  box-sizing: border-box;
  background: #fff;
  margin: 5px auto;
  padding: 20px 70px 20px 50px;
  font-size: 14px;
  position: relative;
  width: 1080px !important;
}

.other-arts {
  background: #16212d;
  width: 100%;
  height: 60px;
  display: none;
  overflow: hidden;
}

.other-arts .arts {
  width: 980px;
  margin: 0 auto;
}

.other-arts .arts a {
  float: left;
  font-family: sanssemibold, arial;
  font-size: 12px;
  display: inline-block;
}

.other-arts .arts a:hover {
  opacity: .9;
}

.other-arts .arts a .image-wrapp {
  width: 80px;
  height: 60px;
  overflow: hidden;
}

.other-arts .arts a .image-wrapp img {
  width: 80px;
  height: auto;
  min-height: 60px;
}

.other-arts .arts a .art-detail {
  color: #d8e1e5;
  border-bottom: 4px solid red;
  width: 216px;
  height: 47px;
  padding: 5px 15px;
  line-height: 15px;
}

.other-arts .arts a .art-detail .category {
  color: red;
  margin-bottom: 3px;
  font-size: 14px;
}

.other-arts .arts a .art-detail .category.horoskopy {
  color: #fa5ab9;
}

.other-arts .arts a .art-detail .category.horoskopy .arrow-link2 {
  border-left-color: #fa5ab9;
}

.other-arts .arts a .art-detail .category.hubnuti {
  color: #24be26;
}

.other-arts .arts a .art-detail .category.hubnuti .arrow-link2 {
  border-left-color: #24be26;
}

.other-arts .arts a .art-detail .title {
  height: 30px;
  overflow: hidden;
}

.other-arts .arts a .art-detail.horoskopy {
  border-bottom-color: #fa5ab9;
}

.other-arts .arts a .art-detail.hubnuti {
  border-bottom-color: #24be26;
}

.float-left, .floatLeft {
  float: left;
}

.float-right, .floatRight {
  float: right;
}

.floatLeft.content480 {
  width: 640px;
}

.floatRight.content480 {
  width: 300px;
}

.loginBoxTop {
  text-align: center;
  z-index: 5;
  color: #16212d;
  background: #fff;
  border: 1px solid #aaa;
  border-radius: 5px;
  width: 610px;
  padding: 5px;
  display: none;
  position: absolute;
  top: 150px;
  left: 180px;
  box-shadow: 0 0 21px 6px rgba(156, 142, 156, .75);
}

.loginBoxTop .super-title {
  margin-top: 30px;
  font-size: 20px;
}

.loginBoxTop .sub-info {
  text-transform: uppercase;
  color: #aaa;
  letter-spacing: 1px;
  margin-top: 10px;
  font-family: sansregular, arial;
  font-size: 10px;
}

.loginBoxTop .main-fields {
  box-sizing: border-box;
  -webkit-text-fill-color: #48535f;
  border-bottom: 1px dashed #cad5df;
  width: 340px;
  margin-left: 137px;
  padding-bottom: 20px;
  box-shadow: inset 0 0 0 50px #fff;
}

.loginBoxTop .main-fields div.field {
  box-sizing: border-box;
  float: left;
  text-align: left;
  width: 160px;
  margin-top: 20px;
  margin-right: 20px;
}

.loginBoxTop .main-fields div.field:nth-child(2) {
  margin-right: 0;
}

.loginBoxTop .side-fields {
  border-bottom: 0;
  width: 520px;
  margin-left: 45px;
}

.loginBoxTop .side-fields div.field:nth-child(3) {
  margin-right: 0;
}

.loginBoxTop .side-fields div.field:nth-child(2) {
  margin-right: 20px;
}

.loginBoxTop label, .loginBoxTop input {
  box-sizing: border-box;
  text-align: left;
  padding: 2px;
  font-size: 12px;
}

.loginBoxTop input[type="submit"] {
  color: #fff;
  text-transform: uppercase;
  background: #2dccd3;
  width: auto;
  margin-top: 20px;
  padding: 10px 30px;
}

.loginBoxTop input {
  border: 1px solid #b6c1cc;
  width: 160px;
  margin-top: 2px;
  padding: 8px;
}

.loginBoxTop .close {
  color: #2dccd3;
  cursor: pointer;
  font-family: open_sanslight, arial;
  font-size: 50px;
  position: absolute;
  top: -10px;
  right: 5px;
  transform: rotate(45deg);
}

.tag-maintitle-simple-text {
  color: #48535f;
  font-family: open_sanslight, arial;
  font-size: 24px;
  font-weight: 300;
  line-height: normal;
}

.header {
  background: #fff;
  width: 940px;
  margin: 0 auto;
  padding: 0;
  font-family: sanssemibold, Arial, sans-serif;
  position: relative;
}

.header .bordered {
  border-bottom: 3px solid #ec86d0;
}

.header nav .links li a, .header .superMenu a {
  text-transform: uppercase;
  color: #848f9b;
  text-align: center;
  width: 130px;
  padding: 20px;
  font-size: 11px;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

.header nav .links li a:hover, .header .superMenu a:hover {
  background: #f7f7f7;
}

.header .superMenu a {
  float: right;
  text-transform: inherit;
  color: #2dccd3;
  width: auto;
  padding: 0 10px 0 0;
}

.header .superMenu a:hover {
  color: #48535f;
  background: none;
}

.header nav {
  width: 100%;
}

.header nav .links {
  clear: both;
  margin: 0;
  padding: 0;
}

.header nav .links li {
  text-align: center;
  float: left;
  border-top: 1px dashed #cad5df;
  list-style-type: none;
}

.header nav .links li a {
  color: #2dccd3;
  width: 70px;
}

.header nav .links li.main-logo {
  border-top: 0;
  width: 230px;
  height: 130px;
  margin: -75px 25px 0;
}

.header nav .links li.main-logo .materstvi-logo {
  background: #fff url("../../../../images/blesk-prozeny/prima-mamy/materstvi-sprite.png?v=9") -5px -1px no-repeat;
  width: 223px;
  height: 127px;
  margin-left: 5px;
  padding: 0;
}

.header .search {
  text-align: right;
  border-left: 1px solid #cad5df;
  width: 40px;
  margin: 35px 0 10px;
}

.header .search a {
  width: 30px;
  padding: 1px;
}

.header .search .search-ico {
  vertical-align: middle;
  background: url("../../../images/blesk-prozeny/prima-mamy/materstvi-sprite.png?v=16") -248px -2px no-repeat;
  width: 20px;
  height: 20px;
  margin-top: 2px;
  display: inline-block;
}

.top-hp {
  margin: 10px 0;
  padding-bottom: 10px;
}

.top-hp a {
  color: #48535f;
  text-decoration: none;
}

.top-hp a:hover {
  text-decoration: underline;
}

.top-hp .arts {
  float: left;
  box-sizing: border-box;
  width: 640px;
  max-height: 465px;
  overflow: hidden;
}

.top-hp .arts .main-art {
  float: left;
  box-sizing: border-box;
  background-clip: padding-box;
  border: 1px solid #cad5df;
  border-radius: 5px;
  width: 449px;
  height: 464px;
  padding-bottom: 10px;
  position: relative;
  overflow: hidden;
}

.top-hp .arts .main-art:hover a {
  color: #2dccd3;
}

.top-hp .arts .main-art .cat-name {
  padding-left: 20px;
}

.top-hp .arts .main-art .cat-name a {
  text-transform: uppercase;
  color: #98a3af;
  font-family: sanssemibold, arial;
  font-size: 10px;
}

.top-hp .arts .main-art .cat-name a:hover {
  color: #2dccd3;
}

.top-hp .arts .main-art .circle-play {
  position: absolute;
  top: 240px;
  right: 20px;
}

.top-hp .arts .main-art .main-title-wrapp {
  width: 448px;
  height: 140px;
  overflow: hidden;
}

.top-hp .arts .main-art .main-title-wrapp h3 {
  padding: 0 20px;
  font-family: sanssemibold, arial;
  font-size: 25px;
  line-height: 30px;
}

.top-hp .arts .main-art .main-img-wrapp {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  height: 300px;
  display: block;
  overflow: hidden;
}

.top-hp .arts .main-art .main-img-wrapp img {
  width: 100%;
  height: auto;
  display: inline-block;
}

.top-hp .arts .small-art {
  box-sizing: border-box;
  float: left;
  border-top: 1px solid #cad5df;
  border-bottom: 1px solid #cad5df;
  width: 150px;
  height: 225px;
  margin-left: 20px;
  padding-top: 20px;
  position: relative;
}

.top-hp .arts .small-art:hover a {
  color: #2dccd3;
}

.top-hp .arts .small-art:last-of-type {
  border-top: 0;
  height: 224px;
  margin-top: 15px;
  padding-top: 5px;
  padding-bottom: 0;
}

.top-hp .arts .small-art:last-of-type .circle-play {
  top: 50px;
}

.top-hp .arts .small-art .circle-play {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 65px;
  right: 10px;
}

.top-hp .arts .small-art .circle-play .arrow-play {
  border-top: 5px solid rgba(0, 0, 0, 0);
  border-bottom: 5px solid rgba(0, 0, 0, 0);
  border-left: 7px solid #fff;
  position: absolute;
  top: 7px;
  left: 10px;
}

.top-hp .arts .small-art .small-title-wrapp {
  width: 150px;
  height: 95px;
  overflow: hidden;
}

.top-hp .arts .small-art .small-title-wrapp h3 {
  font-family: sanssemibold, arial;
  font-size: 16px;
  line-height: 20px;
}

.top-hp .arts .small-art .small-img-wrapp {
  width: 100%;
  height: 80px;
  display: block;
  overflow: hidden;
}

.top-hp .arts .small-art .small-img-wrapp img {
  width: 100%;
  height: auto;
}

.article-wrapp {
  float: left;
  background: #fff;
  border: 1px solid #cad5df;
  border-radius: 5px;
  width: 300px;
  height: 340px;
  margin-bottom: 20px;
  font-family: sanssemibold, arial;
  position: relative;
  overflow: hidden;
}

.article-wrapp:hover .art-title-wrapp h2 a {
  color: #2dccd3;
}

.article-wrapp.odd-item {
  margin-right: 0;
}

.article-wrapp.even-item {
  margin-right: 20px;
}

.article-wrapp .art-img-wrapp {
  width: 100%;
  height: 190px;
  display: block;
  overflow: hidden;
}

.article-wrapp .art-img-wrapp img {
  width: 100%;
  height: auto;
}

.article-wrapp .art-title-wrapp {
  height: 102px;
  padding: 0 20px;
  overflow: hidden;
}

.article-wrapp .art-title-wrapp h2 a, .article-wrapp .art-title-wrapp h3 a {
  color: #16212d;
  font-size: 20px;
}

.article-wrapp .art-title-wrapp h2 a:hover, .article-wrapp .art-title-wrapp h3 a:hover {
  text-decoration: underline;
}

.article-wrapp .cat-name {
  padding-top: 30px;
  padding-left: 20px;
}

.article-wrapp .cat-name a {
  text-transform: uppercase;
  color: #98a3af;
  font-size: 10px;
  display: block;
}

.article-wrapp .cat-name a:hover {
  color: #2dccd3;
  text-decoration: underline;
}

.article-wrapp .circle-play {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 140px;
  right: 20px;
}

.article-wrapp .circle-play .arrow-play {
  border-top: 6px solid rgba(0, 0, 0, 0);
  border-bottom: 6px solid rgba(0, 0, 0, 0);
  border-left: 9px solid #fff;
  top: 9px;
  left: 13px;
}

.hp.even .article-wrapp:nth-child(odd) {
  margin-right: 0;
}

.article-wrapp.maminka-feed, .hp.even .article-wrapp:nth-child(2n) {
  margin-right: 20px;
}

.related-arts .article-wrapp:nth-child(odd) {
  height: 300px;
  margin-right: 0;
}

.related-arts .article-wrapp:nth-child(2n), .article-listed-related-arts .article-wrapp:nth-child(odd) {
  height: 300px;
  margin-right: 20px;
}

.article-listed-related-arts .article-wrapp:nth-child(2n) {
  height: 300px;
  margin-right: 0;
}

.art-from-mam .article-wrapp:nth-child(odd) {
  height: 300px;
  margin-right: 20px;
}

.art-from-mam .article-wrapp:nth-child(2n) {
  height: 300px;
  margin-right: 0;
}

.articles-bottom {
  width: 940px;
}

.articles-bottom .cat-title {
  color: #ec86d0;
  margin: 20px 0 10px;
  font-family: sanssemibold, arial;
  font-size: 20px;
}

.articles-bottom .art-bottom {
  float: left;
  background-clip: padding-box;
  border: 1px solid #cad5df;
  border-radius: 3px;
  width: 217px;
  height: 200px;
  margin-bottom: 20px;
  margin-right: 20px;
  overflow: hidden;
}

.articles-bottom .art-bottom:hover .title a {
  color: #2dccd3;
}

.articles-bottom .art-bottom:hover .cat-subtitle {
  background: #eee;
}

.articles-bottom .art-bottom .cat-subtitle {
  max-height: 20px;
  padding: 5px 5px 5px 10px;
  font-size: 14px;
  overflow: hidden;
}

.articles-bottom .art-bottom .cat-subtitle a {
  color: #98a3af;
  font-family: sansregular, arial;
  line-height: 22px;
}

.articles-bottom .art-bottom .cat-subtitle a:hover {
  color: #2dccd3;
}

.articles-bottom .art-bottom .img-wrapp {
  width: 100%;
  height: 90px;
  overflow: hidden;
}

.articles-bottom .art-bottom .img-wrapp a {
  width: 100%;
  display: inline-block;
}

.articles-bottom .art-bottom .img-wrapp img {
  width: 100%;
  height: auto;
}

.articles-bottom .art-bottom .title a {
  color: #16212d;
  max-height: 60px;
  margin: 10px;
  font-family: sanssemibold, arial;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
}

.articles-bottom .art-bottom .title a:hover {
  text-decoration: underline;
}

.articles-bottom .art-bottom.art3rd {
  margin-right: 0;
}

.editorial {
  box-sizing: border-box;
  background-clip: padding-box;
  border: 1px dashed #cad5df;
  border-radius: 5px;
  width: 300px;
  height: 464px;
  margin-top: 10px;
  padding: 12px 22px;
  font-size: 14px;
  overflow: hidden;
}

.editorial .editorial-pic {
  float: left;
  background-clip: padding-box;
  border: 2px solid #cad5df;
  border-radius: 25px;
  width: 50px;
  height: 50px;
  margin-right: 20px;
  overflow: hidden;
}

.editorial .editorial-pic img {
  width: 50px;
  height: auto;
}

.editorial .editorial-name, .editorial .editorial-date {
  float: left;
  color: #2dccd3;
  width: 190px;
  margin: 4px 0;
  font-family: sanssemibold, arial;
  display: block;
}

.editorial .editorial-date {
  color: #48535f;
  font-family: open_sanslight, arial;
  font-size: 12px;
}

.editorial .editorial-supertitle {
  color: #ec86d0;
  margin-bottom: 10px;
  font-family: sanssemibold, arial;
  font-size: 20px;
}

.editorial .editorial-title {
  width: 225px;
  max-height: 85px;
  margin-bottom: 20px;
  overflow: hidden;
}

.editorial .editorial-title a {
  color: #16212d;
  font-family: sanssemibold, arial;
  font-size: 20px;
}

.editorial .editorial-title a:hover {
  color: #2dccd3;
  text-decoration: underline;
}

.editorial .editorial-body, .editorial .editorial-perex {
  width: 225px;
  max-height: 235px;
  margin: 0;
  line-height: 20px;
  overflow: hidden;
}

.editorial-detail {
  clear: both;
  border-radius: 5px;
  width: 540px;
  font-size: 14px;
}

.editorial-detail h2 {
  color: #ec86d0;
  border-bottom: 1px solid #cad5df;
  margin-bottom: 0;
  padding-bottom: 10px;
  font-size: 30px;
}

.editorial-detail h3 {
  margin: 0;
}

.editorial-detail h3 a {
  color: #16212d;
  font-size: 22px;
}

.editorial-detail h3 a:hover {
  color: #2dccd3;
  text-decoration: underline;
}

.editorial-detail .rec-art {
  border-bottom: 1px dashed #cad5df;
  padding: 15px 0;
}

.editorial-detail iframe {
  margin-left: -10px;
}

.editorial-detail .editorial-text {
  float: left;
  width: 340px;
}

.editorial-detail .editorial-who {
  float: right;
  text-align: right;
  width: 150px;
  overflow: hidden;
}

.seo-box {
  border: 1px solid #cad5df;
  border-radius: 5px;
  margin: 20px 0 0;
  padding: 15px 15px 20px;
  overflow: hidden;
}

.seo-box__header {
  background: #fff;
  margin: 0 0 10px;
  padding: 0;
  font-family: sanssemibold, arial;
  font-size: 22px;
  position: relative;
}

.seo-box__btn {
  color: #fff;
  text-align: center;
  background: #ec86d0;
  border: none;
  width: 200px;
  margin: 0 auto;
  padding: 10px;
  display: block;
}

.seo-box__btn:hover {
  color: #fff;
}

.advisory {
  box-sizing: border-box;
  background: #fff;
  border: 1px dashed #cad5df;
  border-radius: 5px;
  width: 300px;
  max-height: 485px;
  margin-bottom: 20px;
  padding: 10px 20px 20px;
  font-family: sanssemibold, Arial, sans-serif;
  position: relative;
  overflow: hidden;
}

.advisory .category-title {
  border: 0;
}

.advisory .category-title h2 {
  margin-top: 0;
  margin-bottom: 33px;
}

.advisory .art-wrapp {
  border-bottom: 1px solid #cad5df;
  position: relative;
}

.advisory .art-wrapp .art-title-wrapp {
  width: 100%;
  height: 121px;
  max-height: 130px;
}

.advisory .art-wrapp .art-title-wrapp h4 a {
  color: #16212d;
  height: 45px;
  overflow: hidden;
}

.advisory .art-wrapp .art-title-wrapp h4 a:hover {
  text-decoration: underline;
}

.advisory .art-wrapp .art-title-wrapp .name {
  width: 180px;
}

.advisory .art-wrapp .art-title-wrapp .name a {
  font-size: 14px;
}

.advisory .art-wrapp .art-title-wrapp .job {
  color: #48535f;
  font-family: open_sanslight, arial;
  font-size: 12px;
  position: absolute;
  bottom: 3px;
  left: 0;
}

.advisory .art-wrapp .art-title-wrapp .advisor-pic {
  position: absolute;
  top: 51px;
  left: 188px;
}

.advisory .art-wrapp .art-title-wrapp .advisor-pic img {
  width: auto;
  height: 70px;
}

.advisory .art-wrapp.last {
  border-bottom: 0;
}

.owl-buttons div {
  position: absolute;
}

#materstvi-carousel {
  width: 850px;
  margin: 0 auto;
}

.owl-next {
  color: #fff;
  background: url("../../../../images/blesk-prozeny/prima-mamy//materstvi-sprite.png?v=9") -306px -1px no-repeat;
  width: 15px;
  height: 30px;
  top: 20px;
  right: -49px;
}

.owl-prev {
  color: #fff;
  background: url("../../../../images/blesk-prozeny/prima-mamy/materstvi-sprite.png?v=9") -280px -1px no-repeat;
  width: 15px;
  height: 30px;
  top: 20px;
  left: -60px;
}

#materstvi-carousel .owl-prev {
  left: -40px;
}

#materstvi-carousel .owl-next {
  right: -30px;
}

.materstvi-carousel {
  border-top: 1px solid #cad5df;
}

.article-normal #materstvi-carousel {
  width: 550px;
}

.article-normal #materstvi-carousel img {
  width: 125px;
}

.article-normal #materstvi-carousel .owl-prev, .article-normal #materstvi-carousel .owl-next {
  top: 40px;
}

.dictionary-hp {
  float: left;
  background: #fafafa;
  border: 1px solid #cad5df;
  border-radius: 5px;
  margin: 10px 0 30px 40px;
  padding: 0 0 10px 10px;
  overflow: hidden;
}

.dictionary-hp .cat-name {
  text-align: center;
  margin-bottom: 15px;
}

.dictionary-hp .cat-name a {
  color: #ec86d0;
  font-family: sanssemibold, arial;
  font-size: 20px;
  display: inline;
}

.dictionary-hp .dictionary-link {
  color: #16212d;
  width: 150px;
  padding-bottom: 3px;
  text-decoration: none;
  overflow: hidden;
}

.dictionary-hp .dictionary-link .circle-play {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 36px;
  right: 47px;
}

.dictionary-hp .dictionary-link .circle-play .arrow-play {
  border-top: 5px solid rgba(0, 0, 0, 0);
  border-bottom: 5px solid rgba(0, 0, 0, 0);
  border-left: 7px solid #fff;
  top: 7px;
  left: 10px;
}

.dictionary-hp .dictionary-link:hover {
  color: #2dccd3;
}

.dictionary-hp .dictionary-link p {
  text-align: center;
  margin: 0 auto 10px;
  font-family: sanssemibold, arial;
  font-size: 16px;
  line-height: 18px;
}

.dictionary-hp .dictionary-link p img {
  width: 110px;
  height: auto;
}

.dictionary-hp .dictionary-link p.title {
  width: 150px;
  height: 55px;
  font-size: 14px;
  overflow: hidden;
}

.dictionary-hp .dictionary-link p.title:hover {
  text-decoration: underline;
}

.dictionary-hp .dictionary-link p.pic {
  height: 70px;
  overflow: hidden;
}

.main-search-form .cat-name-form {
  margin-top: 10px;
  border-top: 0 !important;
  border-bottom: 0 !important;
}

.main-search-form .cat-name-form .searchForm {
  float: none;
  background-color: #f0f0f0;
  width: 100%;
  height: 40px;
  margin: 0;
  padding: 0;
}

.main-search-form .cat-name-form .searchForm input {
  line-height: 37px;
}

.main-search-form .cat-name-form .searchForm input[type="submit"] {
  float: right;
  background: #f0f0f0 url("../../../../images/blesk-prozeny/prima-mamy/../diety/diety-sprite.png?diety-sprite.png?v=9") -295px -55px no-repeat;
  border: none;
  width: 29px;
  height: 39px;
  padding: 0;
  display: block;
}

.main-search-form .cat-name-form .searchForm input[type="text"] {
  float: left;
  color: #16212d;
  background-color: #f0f0f0;
  border: 0;
  width: 90%;
  padding-left: 10px;
  font-family: open_sanslight, sans-serif;
  font-size: 20px;
}

.main-search-form .searched {
  margin: 10px 0 20px;
  font-family: sanssemibold, arial;
  font-size: 16px;
}

.main-search-form .searched span {
  font-size: 22px;
}

.pagination.pagination-search {
  color: #ec86d0;
}

.pagination.pagination-search a {
  color: #ec86d0 !important;
}

.pagination.pagination-search a.aktpage {
  color: #2dccd3 !important;
}

.pagination.pagination-search a.nextPage, .pagination.pagination-search .pagination a.prevPage {
  background: none !important;
}

.category-arts {
  float: left;
  box-sizing: border-box;
  width: 630px;
  margin: 0 0 50px;
}

.category-arts.article-arts {
  margin-bottom: 0;
}

.category-arts .cat-name {
  border-top: 1px solid #cad5df;
  border-bottom: 1px solid #cad5df;
}

.category-arts .cat-name h1 {
  color: #ec86d0;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: sanssemibold, arial;
  font-size: 32px;
}

.category-arts .first-art {
  width: 630px;
  margin: 0 0 25px;
  font-family: sanssemibold, arial;
  position: relative;
}

.category-arts .first-art:hover .title h2 a {
  color: #2dccd3;
}

.category-arts .first-art .circle-play {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.category-arts .first-art .title h2 a {
  color: #16212d;
  font-family: sanssemibold, arial;
  font-size: 32px;
  line-height: 38px;
}

.category-arts .first-art .title h2 a:hover {
  color: #2dccd3;
  text-decoration: underline;
}

.category-arts .first-art .main-image {
  text-align: center;
  width: 630px;
  max-height: 410px;
  position: relative;
  overflow: hidden;
}

.category-arts .first-art .main-image img {
  width: 630px;
  height: auto;
}

.category-arts .first-art .perex {
  color: #16212d;
  font-size: 16px;
  line-height: 22px;
}

.category-arts .pagination {
  border-bottom: 1px solid #cad5df;
  width: 630px;
  margin-bottom: 30px;
  padding-bottom: 20px;
}

.category-arts .pagination a {
  color: #2dccd3;
  padding: 3px;
  font-family: sanssemibold, arial;
}

.category-arts .pagination .prev-wrapp, .category-arts .pagination .next-wrapp, .category-arts .pagination .page-numbs {
  float: left;
  width: 80px;
  min-height: 1px;
  display: inline-block;
}

.category-arts .pagination .next-wrapp {
  float: right;
  text-align: right;
}

.category-arts .pagination .page-numbs {
  text-align: center;
  width: 465px;
}

.category-arts .pagination .page-numbs .aktpage, .category-arts .pagination .page-numbs .page:hover {
  background: #eee;
}

.category-arts.advisory-page {
  margin-bottom: 10px;
}

.category-arts.left-col {
  border-bottom: 0;
  width: 630px;
  margin: 30px 0;
}

.category-arts.left-col .category-title {
  width: 590px;
  padding-bottom: 5px;
}

.category-arts.left-col .category-title h2 {
  float: left;
  color: #16212d;
  width: 400px;
  margin: 20px 0;
}

.category-arts.left-col .link-themes {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 30px;
}

.category-arts.left-col .link-themes a {
  color: #ec86d0;
}

.category-arts.left-col .art-wrap .rec-art {
  border: 0;
  width: 590px;
  margin-bottom: 0;
  padding: 10px 0 0;
}

.category-arts.left-col .art-wrap .rec-art .art-img-wrapp {
  float: left;
  width: 140px;
  height: 90px;
  margin-right: 20px;
  display: inline-block;
  overflow: hidden;
}

.category-arts.left-col .art-wrap .rec-art .art-img-wrapp .editorial-art {
  text-align: center;
  border: 1px solid #cad5df;
  height: 65px;
  padding-top: 20px;
}

.category-arts.left-col .art-wrap .rec-art .art-img-wrapp img {
  width: 140px;
  height: auto;
}

.category-arts.left-col .art-wrap .rec-art .art-title-wrapp {
  float: left;
  width: 350px;
  height: 80px;
  max-height: 80px;
  overflow: hidden;
}

.category-arts.left-col .art-wrap .rec-art .art-title-wrapp h3 {
  margin-top: 0;
}

.category-arts.left-col .art-wrap .rec-art .art-title-wrapp h3 a {
  color: #48535f;
  font-family: sansregular, arial;
  font-size: 20px !important;
}

.category-arts.left-col .art-wrap .rec-art .art-title-wrapp h3 a:hover {
  text-decoration: underline;
}

.category-arts.editorial-detail {
  float: none;
  width: 550px;
}

.category-arts.editorial-detail .pagination {
  width: 550px;
}

.category-arts.editorial-detail .pagination .page-numbs {
  width: 389px;
}

.category-arts.cat-proverbs {
  float: none;
  border-bottom: 0;
  width: 520px;
  margin-bottom: 30px;
}

.category-arts.cat-proverbs.cat-proverbs-detail #diskuze {
  background: #e9eff4;
}

.category-arts.cat-proverbs.cat-proverbs-detail #diskuze .fb-head h4 {
  color: #000;
  margin: 5px 0 0 7px;
  font-family: sanssemibold, arial;
}

.category-arts.cat-proverbs.cat-proverbs-detail .cat-name {
  width: 430px;
  position: relative;
}

.category-arts.cat-proverbs.cat-proverbs-detail .cat-name h1 {
  float: left;
  width: 430px;
}

.category-arts.cat-proverbs.cat-proverbs-detail .cat-name .proverb-img {
  width: 180px;
  height: 150px;
  position: absolute;
  top: -25px;
  right: -200px;
  overflow: hidden;
}

.category-arts.cat-proverbs.cat-proverbs-detail .cat-name .proverb-img img {
  width: auto;
  height: 150px;
}

.category-arts.cat-proverbs.cat-proverbs-detail h2 {
  border-top: 1px solid #cad5df;
  width: 430px;
  margin-top: 0;
  margin-bottom: 40px;
  padding-top: 20px;
  font-size: 30px;
}

.category-arts.cat-proverbs .cat-name {
  border-bottom: 0;
}

.category-arts.cat-proverbs h2 {
  margin-bottom: 5px;
}

.category-arts.cat-proverbs .arrow-link {
  margin-left: 15px;
  margin-right: 0;
}

.category-arts.cat-proverbs .arrow-link-left {
  margin-right: 15px;
}

.category-arts.cat-proverbs .button {
  width: 110px;
  margin-top: 10px;
  display: block;
}

.category-arts.cat-proverbs .button:hover {
  color: #fff;
}

.category-arts.cat-proverbs .pagination {
  text-transform: uppercase;
  border-bottom: 0;
  width: 520px;
  margin-top: 40px;
  font-family: sanssemibold, arial;
  font-size: 13px;
}

.category-arts.cat-proverbs .pagination .page-numbs {
  width: 350px;
  margin: 3px;
}

.category-arts.cat-proverbs .pagination .page-numbs a {
  padding: 0 5px;
}

.category-arts.cat-proverbs .pagination .page-numbs .aktpage {
  opacity: .5;
  background: none;
}

.category-arts.related-arts .art-title-wrapp h3 a {
  color: #16212d;
}

.category-arts.related-arts .art-title-wrapp h3 a:hover {
  color: #2dccd3;
}

.ra-right-col {
  clear: both;
  border-top: 1px solid #cad5df;
  width: 300px;
  margin-bottom: 20px;
}

.ra-right-col .category-title {
  color: #ec86d0;
}

.ra-right-col .category-title h2 {
  margin: 10px 0 13px;
}

.ra-right-col .art-wrap .rec-art {
  border: 0;
  border-bottom: 1px solid #cad5df;
  margin-bottom: 0;
  padding: 10px 0;
  overflow: hidden;
}

.ra-right-col .art-wrap .rec-art:hover .art-title-wrapp h3 a {
  color: #2dccd3;
}

.ra-right-col .art-wrap .rec-art .art-img-wrapp {
  float: left;
  width: 120px;
  height: 80px;
}

.ra-right-col .art-wrap .rec-art .art-img-wrapp img {
  width: 120px;
  height: auto;
}

.ra-right-col .art-wrap .rec-art .art-title-wrapp {
  float: right;
  width: 160px;
  height: 80px;
  max-height: 80px;
  overflow: hidden;
}

.ra-right-col .art-wrap .rec-art .art-title-wrapp h3 {
  margin-top: 0;
}

.ra-right-col .art-wrap .rec-art .art-title-wrapp h3 a {
  color: #16212d;
  margin-top: 0;
  font-family: sanssemibold, arial;
  font-size: 15px;
  line-height: 20px;
  display: inline-block;
}

.ra-right-col .art-wrap .rec-art .art-title-wrapp h3 a:hover {
  text-decoration: underline;
}

.ra-right-col .show-more {
  color: #fff;
  text-align: center;
  background: #ec86d0;
  width: 200px;
  margin: 20px auto 25px;
  padding: 10px;
}

.ra-right-col .show-more a {
  color: #fff;
}

.article-normal {
  margin-bottom: 20px;
}

.article-normal h1 {
  text-transform: none;
  color: #16212d;
  margin-bottom: 20px;
  line-height: 38px;
}

.article-normal .date, .article-normal .commercial-label {
  color: #707b87;
  float: right;
  margin-bottom: 30px;
  margin-right: 10px;
  font-family: sansregular, arial;
  font-size: 14px;
  line-height: 20px;
}

.article-normal .article-img {
  width: 620px;
}

.article-normal .article-img img {
  width: 620px;
  height: auto;
}

.article-normal .article-img .img-info {
  color: #707b87;
  text-align: left;
  margin: 15px 0 30px;
  font-family: sansregular, arial;
  font-size: 14px;
}

.article-normal .advisor-signature {
  float: right;
  text-decoration: none;
}

.article-normal .fb {
  float: left;
  width: 300px;
  margin-bottom: 30px;
}

.article-normal .fb .fb_share_button {
  margin: 0 15px 0 10px;
}

.article-normal .fb-bottom {
  margin-top: 20px;
}

.article-normal .fb-bottom .fb_share_button {
  margin: 0 15px 0 10px;
}

.article-normal .art-perex {
  margin-bottom: 50px;
  font-family: sanssemibold, arial;
  font-size: 16px;
  line-height: 22px;
}

.article-normal .art-body {
  max-width: 540px;
  margin-bottom: 50px;
  margin-left: 50px;
  font-family: open_sanslight, arial;
  font-size: 16px;
  line-height: 22px;
}

.article-normal .art-body a.more {
  padding: 0 15px 0 5px;
  font-size: 14px;
  background: url("../../../../images/blesk-prozeny/prima-mamy/arrow-green.png") right 7px no-repeat !important;
}

.article-normal .art-perex.advise-art, .article-normal .art-body.advise-art {
  width: 450px;
  margin: 0 0 0 20px;
}

.article-normal .keywords {
  background: #eee;
  margin-bottom: 40px;
  padding: 20px 10px;
  line-height: 25px;
}

.article-normal .keywords .key-title {
  float: left;
  width: 105px;
  margin-right: 10px;
}

.article-normal .keywords .key-title span {
  font-family: sanssemibold, arial;
}

.article-normal .keywords a {
  color: #2dccd3;
  text-decoration: none;
}

.article-normal .keywords a:hover {
  text-decoration: underline;
}

.article-normal .keywords .words {
  float: left;
  width: 480px;
}

.article-normal .relatedArticlesFooter {
  border-bottom: 1px solid #cad5df;
}

.article-normal .author {
  border-top: 1px solid #cad5df;
  border-bottom: 1px solid #cad5df;
  margin-bottom: 20px;
  padding: 15px 0;
}

.article-normal .author .author-data {
  float: left;
  width: 490px;
}

.article-normal .author .author-data .name {
  margin-bottom: 25px;
  font-family: sanssemibold, arial;
  display: inline-block;
}

.article-normal .author .author-data .name a, .article-normal .author .author-data .name a:hover {
  color: #2dccd3;
}

.article-normal .author .author-data .author-art a {
  color: #48535f;
  margin-bottom: 10px;
  font-family: sanssemibold, arial;
  font-size: 14px;
}

.article-normal .author .author-data .author-art a:hover {
  text-decoration: underline;
}

.article-normal .author .author-img {
  float: right;
  text-align: center;
  background: #fbfbfb;
  border: 1px solid #cad5df;
  border-radius: 80px;
  width: 100px;
  overflow: hidden;
}

.article-normal .author .author-img img {
  border-radius: 80px;
  width: auto;
  height: 100px;
}

.article-normal .author .author-img img.radka-wallerova {
  width: 100px;
  height: 140px;
}

.article-normal .author.author-advisor {
  border: 0;
}

.article-normal .author.author-advisor .author-img {
  width: 70px;
  height: 70px;
}

.article-normal .author.author-advisor .author-img img {
  height: 80px;
}

.article-normal .question-circle {
  border: 2px solid #ec86d0;
  border-radius: 50px;
  width: 50px;
  height: 50px;
  margin-top: 15px;
  margin-right: 15px;
}

.article-normal .question-circle .question-mark {
  color: #ec86d0;
  text-align: center;
  margin: 0 0 0 3px;
  font-family: sanssemibold, arial;
  font-size: 38px;
}

.article-normal .itemArticle480 {
  height: auto;
  min-height: 105px;
  margin: 0;
  padding: 0 0 5px 160px;
  position: relative;
}

.article-normal .itemArticle480 a img {
  position: absolute;
  top: 0;
  left: 0;
}

.article-normal .itemArticle480 p {
  margin: 10px 0 0;
  font-size: 15px;
  display: none;
}

.article-normal .itemArticle480 p.title {
  font-size: 20px;
  line-height: 26px;
  display: block;
}

.article-normal .itemArticle480 p a {
  color: #16212d;
  font-family: sanssemibold, arial;
}

.article-normal .itemArticle480 p a:hover {
  text-decoration: underline;
}

.article-normal .itemArticle480 p a.more {
  color: #2dccd3;
  padding: 0 15px 0 5px;
  font-size: 14px;
  background: url("../../../../images/blesk-prozeny/prima-mamy/arrow-green.png") right 7px no-repeat !important;
}

.article-normal table {
  border-collapse: collapse;
  border: 1px solid #cad5df;
  padding: 5px;
}

.article-normal table td {
  border: 1px solid #cad5df;
  padding: 5px;
}

.article-normal .imgInArt {
  border: 1px solid #cad5df;
  max-width: 600px;
  padding: 10px;
  overflow: hidden;
}

.article-normal .imgInArt a, .article-normal .imgInArt img {
  max-width: 590px;
  margin: 0 auto;
  display: block;
}

.article-normal .imgInArt .imgInArtDescription, .article-normal .imgInArt .imgAuthor {
  margin-left: 5px;
}

.article-normal .marginLeft-50 {
  border-top: 1px solid #cad5df;
  border-bottom: 1px solid #cad5df;
  margin: 10px 0;
}

.article-normal .videoInArticle.marginLeft-50 {
  border: 0;
}

.article-normal .relatedArticles {
  float: left;
  background-color: #fafafa;
  border: 2px solid #2dccd3;
  width: 231px;
  margin: 0 20px 15px 0;
  padding: 0 0 10px;
  overflow: hidden;
}

.article-normal .relatedArticles .redDelimiter {
  clear: both;
  background: #fafafa;
  border-bottom: 1px solid #2dccd3;
  height: 10px;
  margin: 0 0 10px;
}

.article-normal .relatedArticles img {
  width: 231px;
}

.article-normal .relatedArticles .headerWhite {
  padding: 0 5px;
}

.article-normal .relatedArticles .headerWhite h4 {
  margin: 10px 5px;
  font-family: sanssemibold, arial;
  font-size: 18px;
}

.article-normal .relatedArticles div.first a.odkaz {
  color: #16212d;
  margin: 5px 10px;
  font-family: sanssemibold, arial;
  font-size: 18px;
  text-decoration: none;
  display: block;
}

.article-normal .relatedArticles div.first a.odkaz:hover {
  color: #2dccd3;
  text-decoration: underline;
}

.article-normal .relatedArticles a.withArrow {
  color: #16212d;
  background: url("../../../../images/blesk-prozeny/prima-mamy/arrow-green.png") -1px 4px no-repeat;
  margin: 0 10px;
  padding: 0 0 0 10px;
  font-family: sanssemibold, arial;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  display: block;
}

.article-normal .relatedArticles a.withArrow:hover {
  color: #2dccd3;
  text-decoration: underline;
}

.article-normal .editorial-detail {
  box-sizing: border-box;
  border: 1px dashed #cad5df;
  padding: 5px 15px 15px 20px;
  position: relative;
}

.article-normal .editorial-detail .fb {
  float: none;
  width: 130px;
  position: absolute;
  bottom: 10px;
  right: -20px;
}

.article-normal .editorial-detail .art-body {
  margin-bottom: 0;
}

.article-normal .editorial-detail .author {
  border: 0;
}

.article-normal .editorial-detail .author .name {
  margin-top: 10px;
}

.article-normal .editorial-detail .author .name a {
  color: #2dccd3;
  font-family: sanssemibold, arial;
  font-size: 17px;
}

.article-normal .editorial-detail .author .name a:hover {
  color: #ec86d0;
}

.article-normal .editorial-detail .author .date {
  float: none;
  margin-right: 0;
  font-size: 13px;
}

.article-normal.article-advisory {
  border-bottom: 1px solid #cad5df;
  margin-bottom: 0;
  padding-bottom: 20px;
}

.article-normal.art-40weeks {
  border: 1px dashed #cad5df;
  border-radius: 5px;
  width: 500px;
  margin-top: 15px;
  padding: 5px 10px;
}

.article-normal.art-40weeks h1 {
  text-align: center;
  float: left;
  width: 310px;
  margin-left: 34px;
  margin-right: 25px;
  font-size: 30px;
}

.article-normal.art-40weeks .article-img {
  text-align: center;
  width: 500px;
}

.article-normal.art-40weeks .article-img img {
  max-width: 300px;
}

.article-normal.art-40weeks .prev-art, .article-normal.art-40weeks .next-art {
  float: left;
  color: #ec86d0;
  text-align: center;
  width: 40px;
  margin: 18px 5px 0;
  font-family: sanssemibold, arial;
  font-size: 12px;
  line-height: 1;
  display: block;
}

.article-normal.art-40weeks .owl-next, .article-normal.art-40weeks .owl-prev {
  float: left;
  background: url("../../../../images/blesk-prozeny/prima-mamy//materstvi-sprite.png?v=9") -306px -42px no-repeat;
  margin-top: 15px;
}

.article-normal.art-40weeks .owl-prev {
  background-position: -280px -42px;
}

.article-normal.art-40weeks .hide {
  visibility: hidden;
}

#diskuze h4 {
  border-top: 1px solid #cad5df;
  margin-bottom: 0;
  padding-top: 10px;
}

#article.notlisted ul.main, #article.notlisted #article.notlisted ul.main {
  margin: 0;
  padding: 0;
  list-style: none;
}

#article.notlisted .listingControlButtons {
  display: none;
}

#article.notlisted .artSectionTop button.showStart {
  display: none !important;
}

#article.listed ul.main, #article.listed #article.notlisted ul.main {
  margin: 0;
  padding: 0;
  list-style: none;
}

#article.listed ul.main > li {
  display: none;
  overflow: hidden;
}

#article.listed ul > li.active {
  display: block;
}

#article.listed button {
  border: 0;
  outline: 0;
}

#article.listed .listingControlButtons {
  display: none;
}

#article.listed .artSectionTop {
  border-top: 1px solid #cad5df;
  padding-top: 5px;
}

#article.listed .artSectionTop .nmbr {
  color: #ec86d0;
  border-right: 1px solid #cad5df;
  margin-right: 10px;
  padding-right: 5px;
}

#article.listed .artSectionTop h2 {
  float: left;
  max-width: 400px;
  margin: 5px 0 15px;
  font-size: 20px;
  display: block;
}

#article.listed .listingControlButtons.smallControlButtons.sectionPaging {
  float: right;
  text-align: right;
  width: 80px;
}

#article.listed .numbers.counter {
  float: left;
  color: #ec86d0;
  text-align: center;
  width: 55px;
  margin: 4px auto 0 5px;
  font-family: sanssemibold, arial;
  font-size: 16px;
  display: none !important;
}

#article.listed .showNext.next, #article.listed .showPrev.prev {
  text-align: center;
  background: url("../../../../images/blesk-prozeny/prima-mamy/materstvi-sprite.png?v=35") -6px -163px no-repeat;
  width: 37px;
  height: 30px;
  display: none;
}

#article.listed .showNext.next {
  background: url("../../../../images/blesk-prozeny/prima-mamy/materstvi-sprite.png?v=3") 6px -128px no-repeat;
}

#article.listed .showStart.start {
  text-align: center;
  background-color: #2dccd3;
  width: auto;
  height: 30px;
  display: none;
  position: relative;
}

#article.listed .showStart.start .text {
  color: #fff;
}

#article.listed .disabled {
  opacity: .5;
}

#article.listed .artSectionBottom {
  float: left;
  width: 120px;
}

#article.listed .artSectionBottom span {
  display: none;
}

#article.listed .bottom .numbers.counter {
  display: none !important;
}

#article.listed #control-bottom {
  float: right;
  width: 80px;
  margin-bottom: 20px;
  position: relative;
}

#article.listed #control-bottom .showNext.startListing {
  color: #fff;
  background: #2dccd3;
  width: 200px;
  padding: 10px;
  font-family: sanssemibold, arial;
  font-size: 15px;
  position: absolute;
  top: -40px;
  left: -350px;
}

#article.listed #related .artSectionTop .relatedArticlesListed .category-arts .category-title h2 {
  float: none;
  font-size: 1.5em !important;
}

.chatArticle .headerComments {
  border-top: 3px solid #404a55;
  font-size: 50px;
  line-height: 60px;
}

.chatArticle .copyThisForm {
  border: 1px dashed #ddd;
  margin: 30px 0;
  padding: 15px;
}

.chatArticle .copyThisForm form textarea {
  color: #48535f;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 495px;
  height: 90px;
  padding: 5px;
}

.chatArticle .copyThisForm form input[type="text"] {
  -webkit-text-fill-color: #48535f;
  color: #48535f;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 190px;
  padding: 5px;
  box-shadow: inset 0 0 0 50px #fff;
}

.chatArticle .copyThisForm form input[type="submit"] {
  color: #fff;
  background: #2dccd3;
  border: 0;
  outline: 0;
  padding: 6px 15px;
  font-size: 14px;
}

.chatArticle .copyThisForm form input[type="submit"]:active {
  border: 1px solid #2dccd3;
}

.chatArticle .copyThisForm .zbyva {
  font-size: 12px;
}

.chatArticle .chatRecords .record {
  border-bottom: 1px solid #cad5df;
  margin: 20px 0;
  padding-bottom: 17px;
}

.chatArticle .chatRecords .record .pic {
  float: left;
  color: #ec86d0;
  background-clip: padding-box;
  border: 3px solid #ec86d0;
  border-radius: 50px;
  width: 60px;
  height: 60px;
  margin: 0 15px 10px 0;
  display: inline-block;
  overflow: hidden;
}

.chatArticle .chatRecords .record .pic img {
  height: 60px;
}

.chatArticle .chatRecords .record .pic.question-pic {
  box-sizing: border-box;
  text-align: center;
  padding: 15px 0 0 3px;
  font-family: sanssemibold, arial;
  font-size: 28px;
  display: none;
}

.chatArticle .chatRecords .record .text {
  float: left;
  width: 455px;
  margin: 10px 0;
}

.chatArticle .chatRecords .record .text.question-text {
  width: 540px;
  font-family: sanssemibold, arial;
}

.chatArticle .chatRecords .record .text.question-text .uzivatel {
  font-size: 13px;
}

.author-profile {
  width: 580px;
}

.author-profile .author-detail {
  border-top: 1px solid #cad5df;
  border-bottom: 1px solid #cad5df;
  margin-bottom: 10px;
  padding: 5px 0 10px;
  position: relative;
}

.author-profile .author-detail .title {
  float: left;
}

.author-profile .author-detail .title h1 {
  text-transform: none;
  color: #ec86d0;
  margin-top: 0;
}

.author-profile .author-detail .author-img {
  text-align: center;
  background: #fbfbfb;
  border: 2px solid #ec86d0;
  height: 60px;
  position: absolute;
  top: 10px;
  left: 520px;
  overflow: hidden;
}

.author-profile .author-detail .author-img img {
  width: auto;
  height: 60px;
}

.author-profile .author-detail .author-img .bg {
  background: url("../../../../images/blesk-prozeny/diety/diety-sprite.png?v=9") -515px -80px no-repeat;
  width: 70px;
  height: 65px;
}

.author-profile .author-detail .job {
  font-size: 14px;
  position: absolute;
  bottom: 10px;
}

.author-profile .author-detail .author-img.nopic {
  background: url("../../../../images/blesk-prozeny/diety/diety-sprite.png?v=9") -520px -80px no-repeat;
  border: 0;
  width: 65px;
  height: 70px;
  display: inline-block;
}

.author-profile .author-info {
  border-bottom: 1px solid #cad5df;
  margin-bottom: 20px;
}

.author-profile .author-info p {
  border-bottom: 0 solid #ddd;
  width: 505px;
  margin-top: 20px;
  padding-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
}

.author-profile .category-arts.left-col {
  margin-top: 0;
}

.author-profile .category-arts.left-col h2 {
  color: #ec86d0;
  font-size: 20px;
}

.first-bottom .articles-bottom {
  border-top: 1px solid #cad5df;
  margin-top: 30px;
}

.advisors, .proverbs-form {
  margin-bottom: 20px;
}

.advisors p, .proverbs-form p {
  margin: 20px 0;
  font-family: sanssemibold, arial;
  font-size: 20px;
  line-height: 20px;
}

.advisors p.einleitung, .proverbs-form p.einleitung {
  margin-bottom: 20px;
  font-family: open_sanslight, arial;
  font-size: 16px;
}

.advisors textarea, .proverbs-form textarea {
  vertical-align: top;
  border: 1px solid #cad5df;
  width: 500px;
  height: 200px;
  margin-left: 3px;
  padding: 5px;
}

.advisors input, .proverbs-form input {
  border: 1px solid #cad5df;
  margin-bottom: 10px;
  padding: 5px;
}

.advisors input#email, .proverbs-form input#email {
  border: 1px solid #cad5df;
  margin-left: 10px;
}

.advisors label, .proverbs-form label {
  margin-right: 20px;
}

.advisors .captchaIn, .proverbs-form .captchaIn {
  padding-left: 102px;
}

.advisors .captchaIn img, .proverbs-form .captchaIn img {
  vertical-align: middle;
  margin-right: 20px;
}

.advisors .captchaIn .submit, .proverbs-form .captchaIn .submit {
  color: #fff;
  background: #2dccd3;
  border: 0;
  margin-left: 40px;
  padding: 12px 30px;
}

.advisors .captchaIn #captcha, .proverbs-form .captchaIn #captcha {
  padding: 12px;
}

.proverbs-form {
  padding-bottom: 30px;
  border-bottom: 1px solid #cad5df !important;
}

.proverbs-form .name {
  width: 225px;
}

.proverbs-form .captchaIn {
  padding-left: 5px;
}

.proverbs-form .captchaIn #captcha {
  box-sizing: border-box;
  padding: 7px;
}

.proverbs-form #forewords {
  height: 120px;
}

.proverbs-form #question {
  height: 160px;
}

.proverbs-form .submit.tipsubmit {
  padding: 6px 44px;
  font-family: sanssemibold, arial;
}

.author-advisor-page.author {
  border-top: 1px solid #cad5df;
  border-bottom: 1px solid #cad5df;
  margin-bottom: 20px;
  padding: 20px 0;
}

.author-advisor-page.author a {
  display: inline;
}

.author-advisor-page.author .author-1 {
  float: left;
  text-align: center;
  color: #16212d;
  width: 140px;
  padding: 7px;
  font-size: 13.5px;
  transition: all .4s;
}

.author-advisor-page.author .author-1:hover {
  cursor: pointer;
  background: #f7f7f7;
}

.author-advisor-page.author .author-1 .author-img {
  float: none;
  background-clip: padding-box;
  border: 3px solid #ec86d0;
  border-radius: 50px;
  width: 70px;
  height: 70px;
  display: inline-block;
  overflow: hidden;
}

.author-advisor-page.author .author-1 .author-img img {
  background-clip: padding-box;
  border-radius: 50px;
  height: 80px;
}

.author-advisor-page.author .author-1 .name {
  margin: 10px auto;
  font-family: sanssemibold, arial;
}

.names {
  width: 540px;
}

.names .face-img {
  float: right;
  text-align: center;
  background: #e9eff4;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  margin-top: 5px;
  font-size: 20px;
  overflow: hidden;
}

.names .face-img .face-wrapp {
  width: 40px;
  height: 40px;
  margin: 6px 0 0 5px;
}

.names .face-img .male {
  background: url("../../../../images/blesk-prozeny/prima-mamy/materstvi-sprite.png?v=9") -160px -130px no-repeat;
}

.names .face-img .female {
  background: url("../../../../images/blesk-prozeny/prima-mamy/materstvi-sprite.png?v=9") -108px -130px no-repeat;
  margin-left: 7px;
}

.names .face-img .none {
  display: none;
}

.names.names-detail {
  box-sizing: border-box;
}

.names.names-detail h2 {
  margin-top: 40px;
  font-family: sanssemibold, arial;
  font-size: 18px;
}

.names.names-detail .name-header {
  border-top: 1px solid #cad5df;
  border-bottom: 1px solid #cad5df;
  padding: 5px 0;
}

.names.names-detail .name-header .name-birth {
  float: left;
  width: 450px;
}

.names.names-detail .name-header .name-birth h1 {
  color: #ec86d0;
  margin: 0;
}

.names.names-detail .name-header .name-birth div {
  font-size: 14px;
}

.names.names-detail .numbers {
  text-transform: uppercase;
  text-align: center;
  border-top: 1px dashed #cad5df;
  border-bottom: 1px dashed #cad5df;
  margin-top: 20px;
  padding: 15px 0;
}

.names.names-detail .numbers p {
  box-sizing: border-box;
  float: left;
  border-right: 1px solid #cad5df;
  width: 49%;
  padding: 0 40px;
  font-size: 14px;
}

.names.names-detail .numbers p:last-of-type {
  border: 0;
}

.names.names-detail .numbers p span {
  color: #ec86d0;
  font-family: sanssemibold, arial;
  font-size: 30px;
}

.names.names-detail .fb {
  margin-top: 20px;
}

.names.names-list .face-img {
  float: left;
  cursor: pointer;
  margin-bottom: 30px;
  margin-right: 30px;
  position: relative;
  overflow: visible;
}

.names.names-list .face-img .male {
  background-position-x: -155px;
}

.names.names-list .face-img .female {
  background-position-x: -110px;
  margin-left: 7px;
}

.names.names-list .sort-choice span {
  margin-right: 30px;
}

.names.names-list .sort-choice .js-czech {
  margin-right: 0;
}

.names.names-list .line-choice {
  margin-bottom: 20px;
}

.names.names-list .alphabet {
  border-top: 1px solid #cad5df;
  border-bottom: 1px solid #cad5df;
  margin-bottom: 30px;
  padding: 8px 0;
}

.names.names-list .alphabet a {
  display: inline;
}

.names.names-list .alphabet a .letter {
  text-transform: uppercase;
  color: #2dccd3;
  text-align: center;
  float: left;
  width: 9px;
  padding: 1px 4px;
  font-family: sanssemibold, arial;
  font-size: 12px;
  display: inline-block;
}

.names.names-list .alphabet a .letter.active {
  color: #fff;
  background: #ec86d0;
  border-radius: 15px;
  margin-top: -3px;
  padding: 3px 7px;
}

.names.names-list .alphabet a .letter:hover {
  color: #fff;
  background: #cad5df;
  border-radius: 15px;
}

.names.names-list .list-names .letter-choice {
  float: left;
  color: #ec86d0;
  text-transform: uppercase;
  width: 90px;
  font-family: sanssemibold, arial;
  font-size: 30px;
}

.names.names-list .list-names .names-choice {
  float: left;
  width: 400px;
  margin: 10px 0 40px;
}

.names.names-list .list-names .names-choice a {
  color: #2dccd3;
  font-family: sanssemibold, arial;
  font-size: 16px;
  text-decoration: none;
}

.names.names-list .list-names .names-choice a:hover {
  text-decoration: underline;
}

.timeline-wrapp {
  margin-bottom: 40px;
  position: relative;
}

.timeline-wrapp .timeline-choice-wrapp {
  z-index: 1;
  background: #b0f0f0;
  border-radius: 5px;
  width: 480px;
  height: 100px;
  position: absolute;
  top: -10px;
  left: 25%;
}

.timeline-wrapp .timeline-choice-wrapp .closer {
  z-index: 2;
  cursor: pointer;
  background: #fff;
  border-left: 10px solid #fff;
  border-bottom-left-radius: 20px;
  position: absolute;
  top: 0;
  right: 0;
}

.timeline-wrapp .timeline-choice-wrapp .closer span {
  color: #2dccd3;
  margin-top: -5px;
  padding-bottom: 5px;
  display: inline-block;
}

.timeline-wrapp .timeline-choice-wrapp .timeline-choice {
  float: left;
  width: 90px;
}

.timeline-wrapp .timeline-choice-wrapp .timeline-choice .select {
  cursor: pointer;
  background-clip: padding-box;
  border: 14px solid #2dccd3;
  border-radius: 14px;
  margin: 15px 10px 5px 30px;
  display: inline-block;
  position: relative;
}

.timeline-wrapp .timeline-choice-wrapp .timeline-choice .select .innerSelect {
  background-clip: padding-box;
  border: 12px solid #fff;
  border-radius: 12px;
  display: inline-block;
  position: absolute;
  top: -12px;
  left: -12px;
}

.timeline-wrapp .timeline-choice-wrapp .timeline-choice .select .innerSelect .choosed {
  background: #ec86d0;
  border-radius: 12px;
  width: 10px;
  height: 10px;
  display: none;
  position: absolute;
  top: -5px;
  left: -5px;
}

.timeline-wrapp .timeline-choice-wrapp .timeline-choice .select .innerSelect.active .choosed {
  display: inline-block;
}

.timeline-wrapp .timeline-choice-wrapp .timeline-choice .choice-text {
  text-align: center;
  font-family: sanssemibold, arial;
  font-size: 14px;
}

.timeline-wrapp .timeline-choice-wrapp .photo {
  float: left;
  background: #ccc;
  border-radius: 20px;
  width: 40px;
  height: 70px;
  margin: 10px 20px 0 0;
}

.birth {
  width: 510px;
}

.birth h2 {
  border-top: 1px solid #cad5df;
  margin-top: 50px;
  margin-bottom: 0;
  padding-top: 10px;
  font-size: 20px;
}

.birth div:after {
  content: " ";
  clear: both;
  height: 0;
  display: block;
}

.birth div p select, .birth div p input {
  border: 1px solid #cad5df;
  max-width: 65px;
  padding: 5px 10px;
}

.birth .result {
  text-align: center;
  background-clip: padding-box;
  border: 1px solid #cad5df;
  border-radius: 5px;
  margin-top: 40px;
  padding: 15px;
  display: none;
}

.birth .result p {
  font-size: 14px;
}

.birth .result h3 {
  margin-top: 0;
}

.birth .result .result-button {
  width: 120px;
  margin-top: 20px;
}

.birth .result .js-expected {
  color: #2dccd3;
  margin-left: 5px;
}

.proverbs {
  border-bottom: 1px dashed #cad5df;
  padding: 5px 0 25px;
  font-size: 14px;
}

.proverbs p {
  margin-bottom: 5px;
}

.proverbs .saying {
  width: 80%;
}

.proverbs .saying .title {
  color: #48535f;
  font-family: sanssemibold, arial;
  font-size: 20px;
}

.proverbs .saying .title:hover {
  text-decoration: underline;
}

.proverbs .saying .circle-separator {
  color: #bbb;
}

.proverbs .eval {
  text-align: right;
  width: 15%;
}

.proverbs .eval .num {
  color: #2dccd3;
  margin: 5px 0 0;
  font-family: sanssemibold, arial;
  font-size: 34px;
}

.proverbs .eval .num.user-num {
  margin-left: 5px;
  font-size: 20px;
}

.proverbs.last {
  border-bottom: 1px solid #cad5df;
}

.proverbs .choice {
  margin-top: 10px;
}

.voteNumbers {
  border-bottom: 1px solid #cad5df;
  margin-top: 40px;
}

.voteNumbers a, .voteNumbers span.star {
  cursor: pointer;
  background: url("../../../../images/blesk-prozeny/prima-mamy/materstvi-sprite.png?v=4") -280px -134px no-repeat;
  width: 30px;
  height: 28px;
  margin: 0 10px 0 0;
  display: inline-block;
}

.voteNumbers a.vote-stat, .voteNumbers span.star.vote-stat {
  background-position: -280px -96px;
}

.voteNumbers a.last, .voteNumbers span.star.last {
  margin-right: 30px;
}

.voteNumbers a.active, .voteNumbers a:hover, .voteNumbers a.active-fix {
  background-position: -280px -96px;
}

.thanks {
  color: #fff;
  background: #2dccd3;
  padding-left: 10px;
  line-height: 30px;
}

.proverbs-right-col {
  border: 1px dashed #cad5df;
  border-radius: 5px;
  padding: 10px 20px 5px;
  overflow: hidden;
}

.proverbs-right-col h2 {
  margin: 0;
}

.proverbs-right-col h2 a {
  font-family: open_sanslight, arial;
  font-size: 20px;
}

.proverbs-right-col .r-art {
  border-bottom: 1px solid #cad5df;
  padding: 15px 0;
}

.proverbs-right-col .r-art:last-of-type {
  border: 0;
}

.proverbs-right-col .r-art .photo {
  width: 60px;
  height: 60px;
  overflow: hidden;
}

.proverbs-right-col .r-art .photo img {
  width: 60px;
  height: auto;
}

.proverbs-right-col .r-art .title {
  width: 180px;
  max-height: 58px;
  overflow: hidden;
}

.proverbs-right-col .r-art .title a {
  color: #48535f;
  font-family: sanssemibold, arial;
  font-size: 20px;
  font-weight: bold;
}

.proverbs-right-col .r-art .title a:hover {
  text-decoration: underline;
}

.horoscopesRight {
  border: 1px solid #cad5df;
  border-radius: 5px;
  width: 290px;
  height: 300px;
  margin: 20px 0 0;
  padding: 10px 5px 5px;
  overflow: hidden;
}

.horoscopesRight .header {
  margin: 0 0 10px 10px;
  font-size: 22px;
}

.horoscopesRight .mainSign .signMain a {
  border-bottom: 1px solid #b2d0dd;
  margin: 0 10px 20px;
  padding-bottom: 5px;
}

.horoscopesRight .mainSign .signMain a span {
  float: left;
  width: 70px;
  height: 100px;
  margin: 0 10px;
  display: block;
  position: relative;
}

.horoscopesRight .mainSign .signMain a span svg {
  margin: auto;
  position: absolute;
  top: 50%;
  bottom: 50%;
}

.horoscopesRight .mainSign .signMain a .name {
  color: #16212d;
  margin-bottom: 5px;
  font-family: sanssemibold, arial;
  font-size: 21px;
}

.horoscopesRight .mainSign .signMain a .text {
  color: #16212d;
  height: 55px;
  font-size: 13px;
  line-height: 19px;
  overflow: hidden;
}

.horoscopesRight .list {
  flex-wrap: wrap;
  justify-content: center;
  display: inline-flex;
}

.horoscopesRight .list a .sign {
  float: left;
  color: #16212d;
  width: 45px;
  margin-bottom: 15px;
}

.horoscopesRight .list a .sign.active {
  font-weight: 700;
  text-decoration: underline;
}

.horoscopesRight .list a .sign:hover {
  text-decoration: underline;
}

.horoscopesRight .list a .sign span {
  width: 30px;
  height: 40px;
  margin: auto;
  display: block;
  position: relative;
}

.horoscopesRight .list a .sign span svg {
  margin: auto;
  position: absolute;
  top: 50%;
  bottom: 50%;
}

.horoscopesRight .list a .sign .caption {
  text-align: center;
  font-size: 10px;
}

.mens #js-men-ranges, .mens #js-ovul-ranges {
  float: left;
  width: 200px;
  display: none;
}

.mens #js-men-ranges p, .mens #js-ovul-ranges p {
  font-family: sanssemibold, arial;
  font-size: 18px;
}

.mens #js-ovul-ranges {
  margin: 0 50px 0 0;
}

.mens p.floatLeft {
  font-family: sanssemibold, arial;
  font-size: 18px;
}

.mens .legend {
  text-align: center;
  margin-top: 30px;
}

.mens .legend .fruit-days span, .mens .legend .period span {
  vertical-align: top;
  background: #ec86d0;
  border: 0;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  margin-left: 20px;
  margin-right: 5px;
  display: inline-block;
}

.mens .legend .fruit-days span {
  background: #2dccd3;
}

.mens #js-calendars.main {
  margin: 0;
}

.mens #js-calendars.main .hidden {
  visibility: hidden;
}

.mens #js-calendars.main .field {
  float: left;
  box-sizing: border-box;
  border-radius: 2px;
  width: 31px;
  margin: 0 1px 0 2px;
  padding: 3px;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
}

.mens #js-calendars.main .men-days, .mens #js-calendars.main .ovul-days {
  color: #fff;
  background: #e261bf;
}

.mens #js-calendars.main .men-days.m2, .mens #js-calendars.main .ovul-days.m2 {
  background: #e672c6;
}

.mens #js-calendars.main .men-days.m3, .mens #js-calendars.main .ovul-days.m3 {
  background: #ec86d0;
}

.mens #js-calendars.main .men-days.m4, .mens #js-calendars.main .ovul-days.m4 {
  background: #f097d7;
}

.mens #js-calendars.main .men-days.m5, .mens #js-calendars.main .ovul-days.m5 {
  background: #f1abdd;
}

.mens #js-calendars.main .men-days.m6, .mens #js-calendars.main .men-days.m7, .mens #js-calendars.main .men-days.m8, .mens #js-calendars.main .men-days.m9, .mens #js-calendars.main .men-days.m10, .mens #js-calendars.main .ovul-days.m6, .mens #js-calendars.main .ovul-days.m7, .mens #js-calendars.main .ovul-days.m8, .mens #js-calendars.main .ovul-days.m9, .mens #js-calendars.main .ovul-days.m10 {
  background: #f4b6e2;
}

.mens #js-calendars.main .ovul-days {
  background: #2dccd3;
}

.mens #js-calendars.main .ovul-days.o1, .mens #js-calendars.main .ovul-days.o5 {
  background: #61e4e9;
}

.mens #js-calendars.main .ovul-days.o2, .mens #js-calendars.main .ovul-days.o4 {
  background: #4ddde3;
}

.mens #js-calendars.main .month {
  text-align: center;
  float: left;
  background: #f4f7f9;
  border-radius: 2px;
  width: 240px;
  min-height: 230px;
  margin: 10px 30px 10px 0;
}

.mens #js-calendars.main .month .day-name span {
  width: 17px;
  margin-bottom: 5px;
  margin-right: 17px;
  font-size: 12px;
  display: inline-block;
}

.mens #js-calendars.main .month .day-name span:last-of-type {
  margin-right: 0;
}

.mens #js-calendars.main .month .month-name {
  text-transform: capitalize;
  border-bottom: 1px solid #cad5df;
  margin-bottom: 5px;
  padding: 10px 5px;
  font-family: sanssemibold, arial;
}

.mens #js-calendars.main .month:nth-child(2n) {
  margin-right: 0;
}

.mens #js-calendars.main .month:nth-child(2n):before {
  content: " ";
  clear: both;
  display: block;
}

.pregnancy-blog {
  margin-top: 20px;
  position: relative;
}

.pregnancy-blog.article-wrapp {
  height: auto;
  overflow: visible;
}

.pregnancy-blog .header {
  color: #98a3af;
  width: 280px;
  margin: 10px 0 10px 20px;
  padding: 0;
}

.pregnancy-blog .header > a {
  color: #98a3af;
}

.pregnancy-blog .header > img {
  position: absolute;
  top: -30px;
  right: 0;
}

.pregnancy-blog .article a {
  color: #16212d;
  width: 100%;
  font-size: 19px;
}

.pregnancy-blog .article a:first-child {
  border-top: 3px solid #2dccd3;
}

.pregnancy-blog .article a .image {
  height: 200px;
}

.pregnancy-blog .article a .image img {
  width: 100%;
  height: auto;
  position: absolute;
}

.pregnancy-blog .article a .title {
  margin: 25px 20px;
}

.pregnancy-blog .show-more {
  color: #fff;
  text-align: center;
  background: #2dccd3;
  width: 200px;
  margin: 0 auto 15px;
  padding: 10px;
}

.pregnancy-blog .show-more a {
  color: #fff;
}

.divider {
  clear: both;
  border: none;
  border-top: 1px solid #cad5df;
  height: 1px;
  margin: 20px 0;
}

.btn-row.text-center {
  text-align: center;
}

.btn-row a.button-main, .btn-row .button-main, .btn-row .main-button {
  color: #fff;
  background: #2dccd3;
  width: auto;
  padding: 20px;
  display: inline-block;
}

.btn-row a.button-main:hover:enabled, .btn-row .button-main:hover:enabled, .btn-row .main-button:hover:enabled {
  color: #fff;
  background: #2dccd3;
  text-decoration: none;
}

.btn-row a.button-main.clicked:hover:enabled, .btn-row .button-main.clicked:hover:enabled, .btn-row .main-button.clicked:hover:enabled {
  background: #02b3bb;
}

.gam-wrapper {
  clear: both;
  display: block;
}

.superMenu {
  position: relative;
}

.loginBox {
  width: auto;
  min-width: 200px;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
}

.cb {
  clear: both;
}

.discussions-maminka {
  box-sizing: border-box;
  border: 1px dashed #cad5df;
  border-radius: 5px;
  width: 300px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 12px 22px;
  overflow: hidden;
}

.discussions-maminka .box-title h2 {
  margin-top: 0;
  margin-bottom: 10px;
}

.discussions-maminka .box-title h2 a {
  color: #00bbb7;
  font-size: 21px;
  font-weight: 700;
}

.discussions-maminka .box-title h2 a:hover {
  color: #126866;
}

.discussions-maminka .discussion-maminka {
  border-bottom: 1px solid #cad5df;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
}

.discussions-maminka .discussion-maminka:before, .discussions-maminka .discussion-maminka:after {
  content: "";
  display: table;
}

.discussions-maminka .discussion-maminka:after {
  clear: both;
}

.discussions-maminka .discussion-maminka .discussion-header {
  float: left;
  border-right: 1px solid #cad5df;
  width: 172px;
}

.discussions-maminka .discussion-maminka .discussion-header .title, .discussions-maminka .discussion-maminka .discussion-header .lastmod {
  display: block;
}

.discussions-maminka .discussion-maminka .discussion-header .title {
  color: #48535f;
  font-family: sanssemibold, arial;
  font-size: 16px;
}

.discussions-maminka .discussion-maminka .discussion-header .lastmod {
  color: #5a5a5a;
  font-family: sansregular, arial;
  font-size: 13px;
}

.discussions-maminka .discussion-maminka .discussion-count-answer {
  float: right;
  width: 66px;
  padding-left: 10px;
}

.discussions-maminka .discussion-maminka .discussion-count-answer .count-answer {
  color: #00bbb7;
  z-index: 1;
  margin-left: 8px;
  font-family: sanssemibold, arial;
  font-size: 16px;
  display: inline-block;
  position: absolute;
  top: 16px;
}

.discussions-maminka .discussion-maminka .discussion-count-answer .icone-answer {
  width: 20px;
  height: 20px;
  margin-top: 4px;
  display: inline-block;
}

.discussions-maminka .discussion-maminka.last {
  border-bottom: 0;
}

.discussions-maminka .show-more {
  color: #fff;
  text-align: center;
  background: #2dccd3;
  width: 200px;
  margin: 15px auto 0;
  padding: 10px;
  display: block;
}

.discussions-maminka .show-more:hover {
  background: #126866;
}

.discussions-maminka .show-more a {
  color: #fff;
  font-family: sanssemibold, arial;
}

@font-face {
  font-family: opensans-regular;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: opensans-bold;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.eot");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.woff") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.cookie-bar {
  text-align: center;
  color: #fff;
  z-index: 200000;
  background-color: rgba(50, 50, 50, .95);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0;
  font-family: opensans-regular, arial, sans-serif;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.cookie-bar a, .cookie-bar a:hover {
  color: inherit;
}

.cookie-bar__title {
  font-family: opensans-bold, arial, sans-serif;
  font-size: 26px;
  line-height: 36px;
}

.cookie-bar__title br {
  display: none;
}

.cookie-bar__text {
  padding: 5px 0 10px;
  font-size: 12px;
  line-height: 16px;
}

.cookie-bar__text br {
  display: inline;
}

.cookie-bar__enable-btn {
  text-transform: uppercase;
  cursor: pointer;
  background-color: red;
  width: 140px;
  height: 35px;
  margin: 0 15px 10px auto;
  font-size: 14px;
  line-height: 35px;
  text-decoration: none;
  display: inline-block;
}

.cookie-bar__more-info {
  margin-bottom: 0;
  font-size: 11px;
  line-height: 15px;
  text-decoration: underline;
  display: inline-block;
}

.cookie-bar__more-info:hover {
  text-decoration: none;
}

.cookie-bar__close-btn {
  cursor: pointer;
  width: 14px;
  height: 14px;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
  overflow: hidden;
}

.cookie-bar__close-btn:before, .cookie-bar__close-btn:after {
  content: "";
  background: #fff;
  width: 100%;
  height: 2px;
  margin-top: -1px;
  position: absolute;
  top: 50%;
  left: 0;
}

.cookie-bar__close-btn:before {
  transform: rotate(45deg);
}

.cookie-bar__close-btn:after {
  transform: rotate(-45deg);
}

.cookie-bar__close-btn--thick:before, .cookie-bar__close-btn--thick:after {
  height: 3px;
  margin-top: -2px;
}

@font-face {
  font-family: sansregular;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot?v=9");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff?v=9") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.ttf?v=9") format("truetype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.svg#open_sansregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: open_sanslight;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-light-webfont.eot?v=9");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-light-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-light-webfont.woff?v=9") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-light-webfont.ttf?v=9") format("truetype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-light-webfont.svg#open_sanslight") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: sanssemibold;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-semibold-webfont.eot?v=9");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-semibold-webfont.woff?v=9") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-semibold-webfont.ttf?v=9") format("truetype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-semibold-webfont.svg#open_sanssemibold") format("svg");
  font-weight: normal;
  font-style: normal;
}

.seo-box {
  text-align: center;
  border: 1px solid #cad5df;
  border-radius: 5px;
  margin: 0 0 20px;
  padding: 15px 15px 20px;
  overflow: hidden;
}

.seo-box a {
  color: #ec86d0;
}

.seo-box a:hover, .seo-box a:focus {
  color: #2dccd3;
}

.seo-box .seo-box__btn {
  text-align: center;
  color: #fff;
  background: #ec86d0;
  border: none;
  width: 200px;
  margin: 0 auto;
  padding: 10px;
  display: block;
}

.seo-box .seo-box__btn:hover, .seo-box .seo-box__btn:focus {
  color: #fff;
  text-decoration: none;
}

.seo-box .toggle-paragraphs {
  cursor: pointer;
  width: 40px;
  margin: 10px auto 0;
  display: block;
}

.seo-box .toggle-paragraphs svg {
  transform: rotate(270deg);
}

.seo-box .toggle-paragraphs path {
  fill: #ec86d0;
}

.seo-box.partialHidedP {
  position: relative;
}

.seo-box.partialHidedP p:not(:first-child) {
  display: none;
}

.seo-box.partialHidedP .seo-box__content {
  border-bottom: none;
  margin-bottom: 0;
  position: relative;
}

.seo-box.partialHidedP .seo-box__content:after {
  content: " ";
  background: linear-gradient(rgba(255, 255, 255, 0), #fff);
  width: 100%;
  height: 100px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.seo-box.partialHidedP .toggle-paragraphs svg {
  transform: rotate(90deg);
}

.seo-box__header {
  background: #fff;
  margin: 0 0 10px;
  padding: 0;
  font-size: 22px;
  position: relative;
}

.seo-box__header a:hover, .seo-box__header a:focus {
  text-decoration: none;
}

.seo-box__content {
  color: #16212d;
  border-bottom: 1px solid #b2d0dd;
  margin: 0 0 20px;
  padding-bottom: 5px;
  font-size: 13px;
  line-height: 19px;
}

.seo-box__content a {
  transition: all .3s;
  display: inline;
}

.seo-box__content a:hover {
  text-decoration: underline;
}

.loading-placeholder {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}

.loading-placeholder img {
  opacity: 0;
  width: 100%;
  transition: opacity 1s;
}

.loading-placeholder.item-loaded {
  padding-bottom: 0 !important;
}

.loading-placeholder.item-loaded img {
  opacity: 1;
}

.loading-placeholder:not(.item-loaded) {
  background-color: #e8e8e8;
  height: auto;
  position: relative;
}

.loading-placeholder:not(.item-loaded):after {
  content: " ";
  background-color: #e8e8e8;
  background-image: url("https://img2.cncenter.cz/images/default/lazy-load/lazyload_placeholder.gif");
  background-repeat: repeat-y;
  background-size: 100% 1px;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.loading-placeholder:not(.item-loaded) img {
  text-indent: -9999px;
  color: rgba(0, 0, 0, 0);
  position: absolute;
}

@keyframes gradient-animation {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

